import React, { useState } from "react"
import { CSSTransition } from 'react-transition-group';
import { Link } from "gatsby"
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildingColumns, faCreditCard, faDisplay, faFileInvoiceDollar, faHandHoldingDollar, faHandshakeSimple, faHeadset, faPeopleGroup, faStar, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import fCheck from "../images/check-solid.svg"
import '../index.css';
import '../styles/home.css'
import Layout from "../components/Layout";
import Modal from "../components/Modal";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const IndexPage = ({data}) => {
  let styles ={
    main_image:{
      backgroundImage:"url(https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650897551/new-cwa/background_wfe9hi.png)",
      backgroundPosition:'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize:'cover',
      backgroundColor: 'rgba(49, 73, 159,.1)',
      backgroundBlendMode:'multiply'
    },
    whitebgsec2:{
      borderTop:"5px solid #14CF74",
      backgroundImage: "url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1642170291/cwa/assets/images/whitebgsec2_ibnqxg.png')",
      backgroundPosition: "center", 
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"

    },
    blackbgsec2:{
      borderTop: "5px solid #3E9CFC",
      backgroundImage: "url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1642170290/cwa/assets/images/blackbgsec2_t4yz2t.png')",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    },
    about:{
      // backgroundColor: "rgba(0,0,0,.5)",
      backgroundBlendMode: "multiply",
      backgroundImage: "url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650921456/new-cwa/pexels-energepiccom-2988232_estxt9.jpg')",
      backgroundPosition: "center", 
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      clipPath:'polygon(200px 0px, 0 100%, 100% 100%, 100% 0)'
      
    },
    hero:{
      position:'relative',
      minHeight:'700px',
      width:'100%',
      // backgroundColor:'rgba(0,0,0,.2)',
      backgroundImage:"url(https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650897551/new-cwa/background_wfe9hi.png)",
      // backgroundImage:"linear-gradient(to left, rgba(255, 255, 255, 0.52), rgba(0,85,140, 0.8)),url(https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651679710/new-cwa/istockphoto-902932468-1024x1024_s3yigy.jpg)",
      // backgroundImage:"url(https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651690252/new-cwa/istockphoto-1281254435-1024x1024_fmexcv.jpg)",
      // backgroundImage:"linear-gradient(to left, rgba(255, 255, 255, 0.52), rgba(0,85,140, 0.8)),url(https://image.shutterstock.com/z/stock-photo-young-happy-woman-in-casual-clothes-at-cafe-buy-breakfast-sit-at-table-hold-wireless-modern-bank-2041448405.jpg)",
      backgroundPosition:'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize:'cover',
      backgroundBlendMode:'multiply'    }
  }
  const [modalOpen, setModal] = useState(false)
  const closeModal = () => {
    setModal(false)
  }

  return(<>
  <Layout>
    <CSSTransition in={modalOpen} timeout={300} classNames="fade" unmountOnExit appear> 
      <Modal closeFn={closeModal} title="Request a Quote" />
    </CSSTransition>
    
    <svg width="0" height="0">
      <linearGradient id="bg" gradientTransform="rotate(90)">
        <stop offset="70%"  stop-color="#0099FF" />
        <stop offset="100%" stop-color="#096FAF" />
      </linearGradient>
    </svg>
    <svg width="0" height="0">
      <linearGradient id="gg" gradientTransform="rotate(90)">
        <stop offset="70%"  stop-color="#14CF74" />
        <stop offset="100%" stop-color="#049850" />
      </linearGradient>
    </svg>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
        <section className="h-1/2 w-full relative" style={styles.hero}>
          <div className="section mx-auto px-5 text-white text-center sm:text-left lg:text-black">
            <div className="my-auto mx-auto vertical-center">
              <h1 className="font-bold leading-none" style={{fontSize:'55px'}}>Discover the CWA Advantage</h1>
              <h2 className="font-bold font-opensans leading-1" style={{fontSize:'25px'}}>We're doing more for merchants than ever before.</h2>
              <div className="mt-5 font-medium text-xl lg:space-x-2">
                <p className="font-medium inline">Zero Cost Processing <img alt='' className="inline" src={fCheck}/> </p>
                <p className="font-medium inline">Next Day Funding <img alt='' className="inline" src={fCheck}/> </p>
                <p className="font-medium inline"><b>FREE</b> Terminals <img alt='' className="inline" src={fCheck}/> </p>
              </div>
              {/* <Link to="/merchants"> */}
                <button onClick={()=>setModal(true)} className="mt-5 rounded hero-button text-lg">Learn More</button>
              {/* </Link>       */}
            </div>
          </div>
        </section>
      </AnimationOnScroll>
      <section>
        <div className="section mx-auto px-5 py-20 w-100">
            <div className="lg:w-1/2 text-center mx-auto mb-10">
              <p className="text-sm subtitle uppercase cw-text-gray">what we offer</p>
              <h2 className="section-title font-bold mb-3">Payment Solutions We Offer</h2>
              <p>Discover our cost-effective credit card processing, innovative payment solutions and round-the-clock support</p>
            </div>
            
            {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-20">
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
                <div id="sales-partner-program" className="rounded-lg p-5 inline-flex">               
                    <Link to="/partner-program">
                      <div className="w-1/12 mr-5 pt-2"><i className="fa-gradient "><FontAwesomeIcon className="text-4xl cw-text-greengradient" icon={faPeopleGroup} /></i></div>
                      <div className="w-11/12 space-y-2">            
                        <h3 className="font-bold text-xl cw-text-green">Sales Partner Program</h3>
                        <p>Generous compensation, a lucrative bonus structure and the freedom to work when you want — if this sounds like the perfect job, 
                          then a career in our Sales Partner Program is made for you.</p>
                        <p className="cw-text-green font-bold">Learn more...</p>
                      </div>
                    </Link>
                </div>
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
                <div id="merchant-services" className="rounded-lg p-5 inline-flex">         
                    <Link to="/merchants">
                      <div className="w-1/12 mr-5 pt-2"><FontAwesomeIcon className="text-4xl cw-text-bluegradient" icon={faHandHoldingDollar} /></div>
                      <div className="w-11/12 space-y-2"> 
                        <h3 className="font-bold text-xl cw-text-lightblue">Merchant Services</h3>
                        <p>Discover how our cost-effective credit card processing, innovative payment solutions and round-the-clock support are tailor-made 
                          to help your business thrive.</p>
                        <p className="cw-text-lightblue font-bold">Learn more...</p>
                      </div>
                    </Link>  
                </div>
              </AnimationOnScroll>
            </div> */}


            <div className="grid grid-cols-1 md:col-span-2 lg:grid-cols-3 gap-x-10 lg:gap-y-10 gap-y-2">
              <div className="rounded-lg p-5 inline-flex">
                <div className="w-1/12 mr-10 pt-2">
                  <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faCreditCard} /></div>
                  {/* <div className="bg-blue-50 rounded-full p-2 w-12 h-12"><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faCreditCard} /></div> */}
                  </div>
                <div className="w-11/12 space-y-2">            
                  <h3 className="font-bold text-md uppercase font-worksans">fast & flexible processing</h3>
                  <p className="text-sm leading-6" >Get more business done at the point of sale when you’re equipped to accept all major credit cards, debit cards, mobile payments, gift cards and EBT.</p>
                </div>
              </div>
              <div className="rounded-lg p-5 inline-flex">
                <div className="w-1/12 mr-10 pt-2">
                  <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faDisplay} /></div>
                  {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faDisplay} /></span> */}
                </div>
                <div className="w-11/12 space-y-2">            
                  <h3 className="font-bold text-md uppercase font-worksans">innovative payment solutions</h3>
                  <p className="text-sm leading-6" >Do business online or on-the-go with innovations like our CWA Card Swiper, state-of-the-art Virtual Terminals and seamless Quickbooks integration.</p>
                </div>
              </div>
              <div className="rounded-lg p-5 inline-flex">
                <div className="w-1/12 mr-10 pt-2">
                  <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faBuildingColumns} /></div>
                  {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faBuildingColumns} /></span> */}
                </div>
                <div className="w-11/12 space-y-2">            
                  <h3 className="font-bold text-md uppercase font-worksans">next day funding</h3>
                  <p className="text-sm leading-6" >Bring every transaction full circle in less than a day with our streamlined, end-to-end credit card processing.</p>
                </div>
              </div>
              <div className="rounded-lg p-5 inline-flex">
                <div className="w-1/12 mr-10 pt-2">
                  <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faHandshakeSimple} /></div>
                  {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faHandshakeSimple} /></span> */}
                </div>
                <div className="w-11/12 space-y-2">            
                  <h3 className="font-bold text-md uppercase font-worksans">merchant referral program</h3>
                  <p className="text-sm leading-6" >Take advantage of our best-in-class Merchant Referral Program and earn credit toward your account each time you make a successful referral.</p>
                </div>
              </div>
              <div className="rounded-lg p-5 inline-flex">
                <div className="w-1/12 mr-10 pt-2">
                  <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faFileInvoiceDollar} /></div>
                  {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faFileInvoiceDollar} /></span> */}
                  </div>
                <div className="w-11/12 space-y-2">            
                  <h3 className="font-bold text-md uppercase font-worksans">small business loans</h3>
                  <p className="text-sm leading-6" >It's true what they say: it takes money to make money. We can help you invest in your business's growth with an attractive term loan or cash advance.</p>
                </div>
              </div>
              <div className="rounded-lg p-5 inline-flex">
                <div className="w-1/12 mr-10 pt-2">
                  <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faHeadset} /></div>
                  {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faHeadset} /></span> */}
                </div>
                <div className="w-11/12 space-y-2">            
                  <h3 className="font-bold text-md uppercase font-worksans">customer support</h3>
                  <p className="text-sm leading-6" >Our Customer Service and Technical Support teams are available to assist you day and night — year round — no matter what.</p>
                </div>
              </div>
            </div>
        
        </div>
      </section>
      
      <section className="cw-bg-bluegradient ">
        <div className="w-100 mx-auto px-5 relative py-20">
          <div className="text-white section mx-auto">
            <div className="lg:w-7/12">
              <p className="text-sm subtitle uppercase">OUR STORY</p>
              <h2 className="text-3xl font-bold mb-3">About CWA</h2>
              <p>When we first started out in the merchant services industry, it was to do more than provide businesses with fast and affordable ways to process credit card 
                payments. We opened our doors because it was our mission to help other businesses thrive. That was over fifteen years ago. Today, thousands of merchants 
                rely on us to help them grow their business thanks to our experience, expertise and preeminent portfolio of processing products and solutions.</p>
            </div>
          </div>
          <div className="hidden lg:block w-5/12 h-full absolute top-0 right-0" style={styles.about}></div>
        </div>
      </section>

      <section className="py-20">
        <div className="section mx-auto px-5 w-100">
          <div className="lg:w-1/2 text-center mx-auto mb-10">
            <p className="text-sm subtitle uppercase cw-text-gray">Services</p>
            <h2 className="section-title font-bold mb-3 max-w-sm mx-auto">Delivering financial support to grow your business</h2>
          </div>
        </div>

        <div className="space-y-10 lg:space-y-5">
          <div className="w-100 h-2/3 mx-auto grid grid-cols-1 lg:grid-cols-2 section  px-5 relative lg:py-36">
            <div className="mx-auto ">
              <div className="">
                <h2 className="text-2xl font-bold mb-3">Investing in your success</h2>
                <p>Securing a loan isn't the only way to invest in your business. Sometimes a simple cash advance is all you need to take advantage 
                  of growth opportunities. Our <b>Cash Advance Program</b> provides you with working capital against future credit sales —so all you have to 
                  do is business as usual and repaying the advance will take care of itself.</p>
              </div>
            </div>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
              <div className="hidden lg:block h-full relative"><img alt='' className="absolute -mt-52 ml-20" src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650985063/new-cwa/Image1_m5lcxq.png"/></div>
            </AnimationOnScroll>
          </div>

          <div className="w-100 mx-auto grid grid-cols-1 lg:grid-cols-2 section  px-5 relative lg:py-36">
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
            <div className="hidden lg:block h-full relative"><img alt='' className="absolute -mt-20 -ml-44" src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650986156/new-cwa/Image2_apm4if.png"/></div>
          </AnimationOnScroll>
            <div className="mx-auto">
              <div className="">
                <h2 className="text-2xl font-bold mb-3">Enjoy faster access to cash</h2>
                <p><b>Next Day Funding</b> means improved cash flow. Improved cash flow means greater flexibility when addressing essential business operations — 
                  like taking care of payroll, purchasing inventory and paying bills. And while most processors will hold your funds for a period of two to 
                  three days, we deliver them to your account the next day at no additional cost to you.</p>
              </div>
            </div>
          </div>

          <div className="w-100 mx-auto grid grid-cols-1 lg:grid-cols-2 section  px-5 relative lg:py-36">
            <div className="mx-auto ">
              <div className="">
                <h2 className="text-2xl font-bold mb-3">Exceptional customer service and support —<b/>any time you need it</h2>
                <p>
                  Our commitment to your success goes beyond providing you with the best products and solutions. Every CWA contract comes with total access to 
                  our <u>around-the-clock customer service and technical support</u>. Because as a business owner, you already have enough to do without also having 
                  to worry about your payment processing.
                </p>
              </div>
            </div>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
              <div className="hidden lg:block h-full relative"><img alt='' style={{height:"500px"}} className="absolute -mt-36 ml-20" src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650986888/new-cwa/Image3_zdibr9.png"/></div>
            </AnimationOnScroll>
          </div>
        </div>
      </section>
      <section className='relative bg-gray-50'>
          <div className="section mx-auto px-5 w-100">
              <div className="w-100 mx-auto grid grid-cols-1 lg:grid-cols-2 section relative py-10 lg:py-20">
                  <div className="hidden lg:block h-full relative">
                      <img className="h-80 w-auto" src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651180931/new-cwa/CBD/undraw_done_re_oak4_htcwb5.svg"/>
                  </div>
                  <div className="my-auto">
                      <div className="">
                          <h2 className="text-2xl font-bold mb-3">High Risk Payment Experts</h2>
                          <p>
                              Merchants that are considered high risk or hard to place are nothing new to us.  Our clients are in verticals that have challenges 
                              securing traditional merchant services and banking depository accounts. We pride ourselves on not only being the experts in this complex 
                              field, but also providing superior service and competitive pricing.  CWA processed over 30M Transactions and over $700M in 2019 for High 
                              Risk industry merchants.
                          </p>
                      </div>
                  </div>
              </div>
          </div>                    
      </section>

      <section className="cw-bg-bluegradient ">
        <div className="w-100 mx-auto px-5 relative py-20">
            <div className="text-white section mx-auto">
            <div className="lg:w-7/12">
                
                <h2 className="text-3xl font-bold mb-5">Why Veterinarians Choose CWA</h2>
                <ul className='font-semibold space-y-2 list-disc pl-5 text-lg' style={{columns:2}}>
                    <li>Veterinary industry experts</li>
                    <li>Interface with ALL practice management software</li>
                    <li>Customizable payment solutions</li>
                    <li>Competitive Rates</li>
                    <li>All processing services are handled in-house</li>
                    <li>Dedicated Veterinary Payment Specialists</li>
                </ul>
                
            </div>
            </div>
            <div className="hidden lg:block w-5/12 h-full absolute top-0 right-0" style={styles.about}></div>
        </div>
      </section>

      <section className='relative partners-section'>
                    {/* <img alt='' className='absolute hidden sm:hidden md:hidden lg:block' style={{height:"500px", width:'auto', bottom:"10px", right:'0px'}} src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651090360/new-cwa/Vets/catcropped_hurftp.png'/> */}
                    <div className='section mx-auto px-5 lg:px-10 py-20 w-100'>
                        <h2 className="text-3xl font-bold mb-3 text-center">Vet Partners & Associations We Work With</h2> 
                        <div className='grid lg:grid-cols-3 grid-cols-2 gap-5 content-center mt-20 mb-28 flex items-center'>
                            <Link to='https://sheltersunited.com/' target={'_blank'}><img alt='' className="m-auto h-24 object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1675348029/new-cwa/Vets/SheltersUnited_Final_Logo_Color_w10zst.png'/></Link>
                            <Link to='https://millervet.supply' target={'_blank'}><img alt='' className="m-auto h-24 object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086213/new-cwa/Vets/Miller-Veterinary-Supply_gk0gaq.png'/></Link>
                            <Link to='https://www.livma.org/' target={'_blank'}><img alt='' className="m-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086224/new-cwa/Vets/livmalogo_c1atun.jpg'/></Link>
                            <Link to='https://vetevolve.com/' target={'_blank'}><img alt='' className="m-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086235/new-cwa/Vets/vetevolve-logo-color_f4frpb.png'/></Link>
                            <Link to='https://vetmg.com/' target={'_blank'}><img alt='' className="m-auto h-24 w-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086010/new-cwa/Vets/VMG-logo-web_ctemta.jpg'/></Link>
                            
                            {/* <img alt='' className="m-auto object-scale-down grayscale" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086012/new-cwa/Vets/petvet-logo_1_x7chei.png'/> */}
                            {/* <img alt='' className="m-auto object-scale-down grayscale" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086013/new-cwa/Vets/brp_owler_20190706_130921_original_zw63z9.png'/> */}
                            {/* <img alt='' className="m-auto object-scale-down grayscale" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086015/new-cwa/Vets/Encore-logo-blue_g9cgae.png'/> */}
                            <Link to='https://www.monarchbusinessconsulting.com/' target={'_blank'}><img alt='' className="m-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086017/new-cwa/Vets/monach-logo_alrogl.svg'/></Link>
                            {/* <Link to='https://premiervetalliance.com/uk/services/premier-pet-care-plan/' target={'_blank'}><img alt='' className="m-auto h-24 w-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086019/new-cwa/Vets/premier_pet_care_plan_logo_ufw2cs.png'/></Link> */}
                            {/* <img alt='' className="m-auto object-scale-down grayscale" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086021/new-cwa/Vets/Rarebreed-Color_xprmvu.svg'/> */}
                        </div>                      
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-5 text-gray-500 leading-6'>
                            <div className='px-10 relative border-r border-gray-200 '>
                                <FontAwesomeIcon style={{fontSize:"80px"}} className="-mb-2 z-0 opacity-25 text-blue-300" icon={faQuoteLeft}/>
                                <div className='z-1 '>
                                    <p className='italic' style={{minHeight:'130px'}}>
                                        My experience with CWA Merchant Services has been entirely positive. Their technical support is outstanding, but what I most appreciate 
                                        is that they don't charge more for “card not present” transactions. As a mobile practice, I process at least half of my credit cards this way. 
                                        CWA has saved me a ton of money.
                                    </p>
                                    <div className=''>
                                        <div className='text-yellow-400 text-xl'>
                                            <FontAwesomeIcon icon={faStar}/>
                                            <FontAwesomeIcon icon={faStar}/>
                                            <FontAwesomeIcon icon={faStar}/>
                                            <FontAwesomeIcon icon={faStar}/>
                                            <FontAwesomeIcon icon={faStar}/>
                                        </div>
                                        <p className='cw-text-primary font-semibold'>Dani Cimino, DVM - Owner</p>
                                        <p className='text-gray-400'>North Deering Veterinary Hospital</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='px-10 relative'>
                                <FontAwesomeIcon style={{fontSize:"80px"}} className="-mb-2 z-0 opacity-25 text-blue-300" icon={faQuoteLeft}/>
                                <p className='italic' style={{minHeight:'130px'}}>  
                                    We have been delighted with CWA. Their customer service is always friendly, knowledgeable, and very quick to respond. We have been 
                                    dealt with completely and professionally. I would recommend this service to anyone. Their rates are very fair, and we have saved since 
                                    joining them.
                                </p>
                                <div className=''>
                                    <div className='text-yellow-400 text-xl'>
                                        <FontAwesomeIcon icon={faStar}/>
                                        <FontAwesomeIcon icon={faStar}/>
                                        <FontAwesomeIcon icon={faStar}/>
                                        <FontAwesomeIcon icon={faStar}/>
                                        <FontAwesomeIcon icon={faStar}/>
                                    </div>
                                    <p className='cw-text-primary font-semibold'>Gina Glidden - Office Manager</p>
                                    <p className='text-gray-400'>Crossroads Mobile Vet</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

      <section className="cw-bg-bluegradient">
        <div className="w-100 mx-auto px-5 relative py-12 text-center space-y-5">
          <h2 className="text-white text-4xl font-semibold">Get Started with CWA Today</h2>
          <button onClick={()=>setModal(true)} className="btn-white">Request a quote</button>
        </div>
      </section>
    </Layout>
</>)}
export default IndexPage
